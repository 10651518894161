// import carouselRunner from './carouselRunner'
import 'jquery'
import '../scss/style.scss.nofouc'

import AOS from 'aos'
import 'aos/dist/aos.css'

$(document).ready(($) => {
    AOS.init()

    window.addEventListener('load', function () {
        AOS.refresh();
    });

//     $('.questions-menu a').first().click(function (e) {
//         e.preventDefault()
//         $('.questions-menu ul').toggleClass('active')
//         // $('.questions-menu ul ul').toggleClass('active');
//     })
//
    $('.hamburger').click(function (e) {
        $(this).toggleClass('is-active')
        $('.nav-main').toggleClass('is-active')
    })
//
//     $('.tabbed-content-button').click(function (e) {
//         e.preventDefault()
//         $('.tabbed-content-button').removeClass('active')
//         $(this).toggleClass('active')
//         // $('.tab').addClass('inactive').removeClass('active');
//         var tab = '.' + $(this).attr('rel')
//         // $(tab).removeClass('inactive').addClass('active');
//
//         $('.hidden-items').append($('.tab'))
//         $('.masterclasses_date_and_location').after($(tab))
//
//         google.maps.event.trigger(map, 'resize')
//     })
//
//     $('[rel="tab1"]').addClass('active')
//     $('.hidden-items').append($('.tab'))
//     $('.masterclasses_date_and_location').after($('.tab1'))
})
//
// setMainMenuScroll()
// carouselRunner()

$(window).scroll(function () {
    setMainMenuScroll()
    setHeaderPosition()
})

function setMainMenuScroll() {
    if ($(window).scrollTop() > 100) {
        $('.nav-main').addClass('scrolled')
    } else {
        $('.nav-main').removeClass('scrolled')
    }
}

function setHeaderPosition() {
    $('.main-content-header').css('background-position', 'center ' + (-100 + ($(window).scrollTop() / 2)) + 'px')
    $('.main-content-header-post').css('background-position', 'center ' + (-100 + ($(window).scrollTop() / 2)) + 'px')
}
